import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Flag from 'react-world-flags';
import Flip from 'react-reveal/Flip';

import Layout from '../components/layout';
import SEO from '../components/seo';

import student from '../images/index/students.jpg';

export const pageQuery = graphql`
  query {
    films: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/films/**/*.md" }
        frontmatter: { classe: { eq: "Festival" } }
      }
      sort: { order: ASC, fields: [frontmatter___time] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          html
          excerpt
          frontmatter {
            title
            category
            meta
            time
            classe
            affiche {
              childImageSharp {
                fluid(quality: 100, maxWidth: 400, maxHeight: 540) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;

const FestivalPage = ({ data }) => (
  <Layout>
    <SEO
      title="Festivals / Evènements - Cinélangues"
      description="Cinélangues poursuit, depuis de nombreuses années maintenant, toujours avec autant de passion et de conviction, son action de diffusion des cinématographies."
      pathname="/"
    />
    <div className="page">
      <div className="container">
        <div className="container jumbotron">
          <div className="row">
            <div className="col-sm-12 col-md-8 header-content my-auto">
              <h1>
                <i className="fa fa-calendar ml-2 mr-2"></i>Evènements
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container films ">
        <div className="row">
          <div className="col-lg-12">
            <div className="row no-gutter">
              {data.films.edges.map(({ node: post }) => (
                <div key={post.id} className="col-6 col-sm-6 col-md-4 col-lg-3">
                  <Flip right cascade>
                    <div className="card">
                      <Link to={post.fields.slug}>
                        <Img
                          fluid={post.frontmatter.affiche.childImageSharp.fluid}
                          alt={post.frontmatter.title}
                        />
                        <div className="text">
                          <div className="row no-gutter">
                            <h2 className="card-title text-capitalize col-12 col-sm-9 col-md-7">
                              {post.frontmatter.category === 'Espagnol' ? (
                                <Flag code="es" height="11" />
                              ) : null}
                              {post.frontmatter.category === 'Allemand' ? (
                                <Flag code="de" height="11" />
                              ) : null}
                              {post.frontmatter.category === 'Italien' ? (
                                <Flag code="it" height="11" />
                              ) : null}
                              {post.frontmatter.title}
                            </h2>
                            <div className="col-sm-7 col-md-5 badges">
                              <span className="badge badge-pill badge-warning">
                                {post.frontmatter.classe}
                              </span>
                              <span className="badge badge-pill badge-danger">
                                {post.frontmatter.time}
                              </span>
                            </div>
                            <div className="col-lg-12">
                              <small>{post.frontmatter.meta}</small>
                            </div>
                            <p>
                              <div
                                dangerouslySetInnerHTML={{ __html: post.excerpt }}
                              ></div>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Flip>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default FestivalPage;
